import React from "react";
import styles from "./bg.module.scss";
import { useOnScreen } from "../../../hooks/useOnScreen";

const BgLayout = ({ children }) => {
  const [ref, isVisible] = useOnScreen({ threshold: 0});
  return <div ref={ref} className={isVisible ? styles.bg : ""}>{children}</div>;
};

export default BgLayout;
